import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import Parser from "../components/parser.js";

const Page = (props) => {
  const page = props.data ? props.data.wpPage : props;
  return (
    <Layout 
      infos={{
        title: page.title, 
        postType: "page", 
        path: props.pageContext.path, 
        parent: {
          url: props.pageContext.parentUrl,
          title: props.pageContext.parentTitle
        } 
      }} 
      seo={ page.seo || {} }>
      <Parser content={ page.content } />
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      content
      seo {
        canonicalUrl
        description
        openGraphDescription
        openGraphTitle
        openGraphType
        title
        twitterDescription
        twitterTitle
        noIndex
        excludeLocalSearch
        excludeFromArchive
        noArchive
        noFollow
        redirectUrl
        socialImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
export default Page;
